<template>
  <div class="um-cornerstone um-cornerstone--home">
    <wl-cornerstone-banner
      title="100% FREE People Search"
      sub-text="Find Phone Numbers, Email Addresses, Social Media, Criminal Records and More!"
      :banner-image="peopleImage"
      :prefill-fields="$parent.$attrs['prefill-fields']"
      :search-tabs="cms.cornerstone.banner.search_tabs"
      :button-icon="cms.cornerstone.banner.button_icon"
      :search-icon-size="cms.cornerstone.banner.button_icon_size"
      :review-text="cms.cornerstone.banner.review_text"
      :secured-text="cms.cornerstone.banner.secured_text"
      :rating-image="cms.cornerstone.banner.rating_image"
      :people-url="cms.cornerstone.banner.people_url"
      :no-query-string="true"
      :fallback-fields="$parent.$attrs['fallback-fields']"
      :default-state-option-label="
        cms.cornerstone.banner.default_state_option_label
      "
    />

    <div
      class="um-cornerstone-section um-cornerstone-section--extra-space um-cornerstone-section--center-text"
    >
      <div class="um-cornerstone-section__container">
        <h2>What Data Can I Get from a People Search Tool?</h2>
        <p>
          A people search tool can provide a wealth of data about an individual.
          This data can include contact information, criminal records, and
          employment history. When conducting a people search, it is important
          to know what type of data you are looking for. This will help you
          choose the right people search tool for your needs.
        </p>
        <ul class="um-cornerstone-section__list">
          <li class="um-cornerstone-section__list-item">
            <div
              class="um-cornerstone-section__item-head um-cornerstone-section__item-head--contact"
            >
              <h3 class="um-cornerstone-section__item-title">
                <span>Contact Information</span>
              </h3>
            </div>
            <p>
              This type of data includes the individual’s name, address, phone
              number, and email address.
            </p>
          </li>
          <li class="um-cornerstone-section__list-item">
            <div
              class="um-cornerstone-section__item-head um-cornerstone-section__item-head--employment"
            >
              <h3 class="um-cornerstone-section__item-title">
                Employment History
              </h3>
            </div>
            <p>
              This type of data can include past employers, job titles, and
              dates of employment.
            </p>
          </li>
          <li class="um-cornerstone-section__list-item">
            <div
              class="um-cornerstone-section__item-head um-cornerstone-section__item-head--criminal"
            >
              <h3 class="um-cornerstone-section__item-title">
                <span>Criminal Records</span>
              </h3>
            </div>
            <p>
              This type of data can include arrests, convictions, and
              outstanding warrants.
            </p>
          </li>
          <li class="um-cornerstone-section__list-item">
            <div
              class="um-cornerstone-section__item-head um-cornerstone-section__item-head--education"
            >
              <h3 class="um-cornerstone-section__item-title">
                <span>Education History</span>
              </h3>
            </div>
            <p>
              This type of data can include the individual’s highest level of
              education, schools attended, and majors studied.
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div
      class="um-cornerstone-section um-cornerstone-section--color-primary um-cornerstone-section--center-text"
    >
      <div class="um-cornerstone-section__container">
        <h2>Other Types of Data</h2>
        <p class="um-cornerstone-section__para">
          This data can be extremely helpful in a variety of situations. If
          you're trying to find a long-lost friend or family member, you can use
          the personal information to track them down. Or, if you're concerned
          about someone you're dating, the criminal records check can give you
          some peace of mind.
        </p>
        <ul
          class="um-cornerstone-section__list um-cornerstone-section__list--multi-columns"
        >
          <li
            class="um-cornerstone-section__list-item um-cornerstone-section__list-item--primary"
          >
            Court records
          </li>
          <li
            class="um-cornerstone-section__list-item um-cornerstone-section__list-item--primary"
          >
            Age and date of birth
          </li>
          <li
            class="um-cornerstone-section__list-item um-cornerstone-section__list-item--primary"
          >
            Marital status
          </li>
          <li
            class="um-cornerstone-section__list-item um-cornerstone-section__list-item--primary"
          >
            Relatives
          </li>
          <li
            class="um-cornerstone-section__list-item um-cornerstone-section__list-item--primary"
          >
            Social media accounts
          </li>
          <li
            class="um-cornerstone-section__list-item um-cornerstone-section__list-item--primary"
          >
            Professional License Records
          </li>
          <li
            class="um-cornerstone-section__list-item um-cornerstone-section__list-item--primary"
          >
            Property Ownership records
          </li>
          <li
            class="um-cornerstone-section__list-item um-cornerstone-section__list-item--primary"
          >
            Sexual Offender Status
          </li>
          <li
            class="um-cornerstone-section__list-item um-cornerstone-section__list-item--primary"
          >
            Traffic Violations
          </li>
        </ul>
        <p>
          Generally speaking, the more detailed and comprehensive the people
          search, the more data you'll be able to access. However, even basic
          people searches can provide a wealth of information. So, if you need
          to find someone or get more information on them, a people search is a
          great place to start.
        </p>
      </div>
    </div>

    <div class="um-cornerstone-section um-cornerstone-section--color">
      <div class="um-cornerstone-section__container">
        <div class="um-cornerstone-section__row">
          <div class="um-cornerstone-section__columns">
            <img
              class="um-cornerstone-section__image"
              :src="handsPhoneImage"
              alt="Hands looking up information on a phone"
              width="388"
              height="388"
              loading="lazy"
            />
          </div>
          <div class="um-cornerstone-section__columns">
            <h2>What Is a People Search Engine?</h2>
            <p>
              A people search engine is a tool that allows you to find
              information about people. You can use a people search engine to
              find someone's contact information, social media profiles, and
              public records.<br /><br />

              People search engines are useful for finding old friends, doing
              background checks, and connecting with new people.<br /><br />

              When you use a people search engine, you will enter the person's
              name and location. The results will show you a list of people with
              that name in that location. From there, you can click on a
              person's profile to view more information about them.<br /><br />

              People search engines are different from regular search engines
              like Google because they focus specifically on people. This makes
              them much more effective for finding people-related
              information.<br /><br />

              If you're looking for someone, a people search engine is a great
              place to start. With a little bit of information, you can find out
              a lot about someone. So go ahead and give it a try!
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="um-cornerstone-section um-cornerstone-section--center-text um-cornerstone-section--no-space"
    >
      <div class="um-cornerstone-section__container">
        <h2>
          What Can People Search Be Used For?
        </h2>
        <p class="um-cornerstone-section__para">
          If you're considering using a people search, it's important to keep
          these things in mind. Otherwise, you could end up making a major
          mistake that could have serious consequences.
        </p>
        <ul
          class="um-cornerstone-section__list um-cornerstone-section__list--use"
        >
          <li class="um-cornerstone-section__list-item">
            <p>
              People search engines are particularly useful when you need to
              find someone who is not easily reachable by other means. For
              example, if you are trying to locate an old friend or forgotten
              relative, a people search engine can help you find their current
              contact information.<br /><br />
              A background check is another common use for people search
              engines. If you are considering subcontracting someone for a
              service or entering into a business relationship with someone, you
              may want to run a background check on them first. This can help
              you uncover any red flags that might be cause for concern.<br /><br />
              Whether you are trying to find someone's contact information,
              research their background, or learn more about their work history,
              people search engines can be a valuable tool. With just a few
              clicks, you can access information about the people in your life.
            </p>
          </li>
          <li class="um-cornerstone-section__list-item">
            <p>
              Most people are familiar with the idea of a people search —
              googling someone's name to find out more about them. However, what
              people may not realize is that there are certain things that a
              people search should not be used for.<br /><br />

              A people search should not be used for determining someone's
              suitability for employment, creditworthiness, or any other purpose
              that could have a negative financial or legal consequence for the
              person being searched. Additionally, people searches should not be
              used to stalk or harass another person, and doing so could result
              in criminal charges. People searches should also not be conducted
              on minors without the permission of their parents or guardians.<br /><br />

              Furthermore, a people search should not be used to solicit money
              or personal information from someone. This is known as phishing
              and can be a form of identity theft.
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div class="um-cornerstone-section">
      <div class="um-cornerstone-section__container">
        <h2>Why Conduct a Background Check?</h2>
        <p class="um-cornerstone-section__para">
          Background checks are an important tool for businesses and individuals
          alike. They can provide peace of mind and help people avoid bad
          situations.<br /><br />

          There are many reasons why you might want to conduct a background
          check on someone. Maybe you want to search yourself and see what
          information is being shown to others. Perhaps you're going on a date
          with someone you met online, and you want to do a little research
          before meeting them in person to be sure they're not hiding anything
          dark from their past. Or maybe you're just curious about what people
          are saying about your favorite celebrity.<br /><br />

          Whatever your reasons, there are some simple steps you can follow to
          conduct a successful background check.
        </p>
        <ul
          class="um-cornerstone-section__list um-cornerstone-section__list--primary"
        >
          <li>
            First, you'll need to gather some basic information about the person
            you're checking up on. You'll need their full name and current or
            previous state of residence. If you have any other information, such
            as an email address or phone number, that can be helpful as well.
          </li>
          <li>
            Next, you'll want to run a search on the person's name. This can be
            done easily through a people search engine like Unmask.
          </li>
          <li>
            From there, you can order a background check report. These reports
            can give you a good overview of the person's criminal history,
            employment history, and more.
          </li>
          <li>
            Finally, once you have all of this information, you'll want to sit
            down and review it carefully. Look for any red flags that might
            indicate that the person is not who they say they are.
          </li>
        </ul>
      </div>
    </div>
    <div class="um-cornerstone-section um-cornerstone-section--color">
      <div class="um-cornerstone-section__container">
        <h2>Search for People with Unmask</h2>
        <p class="um-cornerstone-section__para">
          If you're looking for someone, and traditional people search engines
          just aren't giving you the results you need, it might be time to try a
          people search with Unmask. Unmask is a people search engine that can
          help you find anyone, anywhere within the United States.<br /><br />
          Whether you're looking for an old friend, trying to find out more
          about a new neighbor, or checking up on a potential business partner,
          Unmask can help. With our powerful people search tools, you can find
          anyone you're looking for quickly and easily.<br /><br />
          Plus, our people search is completely confidential. We never share
          your information with anyone without your permission, so you can rest
          assured that your search is private and secure.<br /><br />
          So if you're ready to find someone, try a people search with Unmask
          today.
        </p>
        <a
          href="#"
          @click.prevent="scrollToTop"
          class="um-cornerstone-section__button"
          >Start searching now</a
        >
      </div>
    </div>
    <um-people-directory
      v-for="cardList in renderPeopleDirectory()"
      key="directory"
      :cms="cms.page.people_directory"
      :card-list="cardList"
    />
  </div>
</template>

<script>
// Components
import WlCornerstoneBanner from '@/components/shared/wl-cornerstone-banner';
import UmPeopleDirectory from '@/sections/um-people-directory';
import UmBlog from '@/sections/um-blog';
// Images
import handsPhoneImage from '@/assets/images/cornerstone/people-search-unmask.webp';
import peopleImage from '@/assets/images/people.webp';
// Helpers
import Meta from '@/assets/js/shared/misc/meta.mjs';
import getPeopleDirectoryData from '@/assets/js/shared/helpers/getPeopleDirectoryData';

export default {
  components: {
    WlCornerstoneBanner,
    UmBlog,
    UmPeopleDirectory
  },
  async asyncData() {
    if (!process.server) {
      return;
    }

    return getPeopleDirectoryData();
  },
  data() {
    return {
      showLoader: false,
      handsPhoneImage,
      peopleImage
    };
  },
  methods: {
    renderPeopleDirectory() {
      return this.people && this.people.length > 0 ? [this.people] : [];
    },
    scrollToTop() {
      scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: require(`@/assets/cms/pages/home.json`),
          cornerstone: require(`@/assets/cms/pages/cornerstone.json`),
          global: require(`@/assets/cms/global.json`)
        };
      }
    }
  },
  head() {
    return {
      title: this.cms.page.page_title,
      meta: this.cms.page.meta ? Meta.convertToNuxtMeta(this.cms.page.meta) : []
    };
  }
};
</script>
