<template>
  <section class="um-people-directory">
    <header class="um-people-directory__header">
      <h2 class="um-people-directory__title" v-text="cms.title" />
      <tz-markdown
        class="um-people-directory__description"
        :content="cms.subtitle"
      />
    </header>
    <div class="um-people-directory__container">
      <article class="um-people-directory__card" v-for="card in cardList">
        <h3 class="um-people-directory__card-title">
          <a
            class="um-people-directory__card-link"
            :href="
              `/people/${card.letter}${card.primaryKey}${card.secondaryKey}/`
            "
            v-text="card.range.replace('|', ' ')"
          />
        </h3>
        <p
          class="um-people-directory__card-text"
          v-text="`${cms.list_label} ${card.data.join(', ')}`"
        />
      </article>
    </div>
  </section>
</template>
<script>
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';

export default {
  name: 'um-people-directory',
  components: {
    TzMarkdown
  },
  props: {
    cms: {
      type: Object
    },
    cardList: {
      type: Array
    }
  }
};
</script>
